import React, { useState } from "react";
import PaymentMethod from "./PaymentMethod";
import DonorsInfo from "./DonorsInfo";
import Header from "./Header";
import {api_root, check_status, sample_function} from './api';
import Footer from "./Footer";
import $ from "jquery";
import Swal from "sweetalert2";
import TransactionType from "./TransactionType";

function Contributions(){

    const [showDonorsInfo, setShowDonorsInfo] = useState(false);
    const [donorsInfoData, setDonorsInfoData] = useState(null); // State to hold the response data
    const [hasError, setHasError] = useState(false); // State to track the error status

    function validate_campaign_id(campaign_id){
        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })

        $.ajax({
            method: 'GET',
            url: api_root()+'/validate_campaign_id/',
            dataType: 'json',
            data: {campaign_id:campaign_id},
            beforeSend: function() {
            },
            complete: function() {
            },

            success: function(json) {
                Swal.close();
                if(json.error){
                    $("#campaign_id").val("")
                    Swal.close()
                    Swal.fire({
                        title: "Invalid Campaign ID",
                        icon: "error",
                        html: '<div class="text-danger m-1"> This Campaign ID doesn\'t exist </div>',
                        showConfirmButton: false,
                        allowOutsideClick: !0
                    })
                    $(".additionals").html("")
                    setHasError(true);
                    setDonorsInfoData(null);
                    return
                }

                var html = '' +
                    '<input type="hidden" id="customer" class="form-control" name="customer" value="'+json.customer+'" readonly/>\n' +

                    '<div class="mb-3">\n' +
                    '  <label for="campaign_name" class="form-label">Campaign Name</label>\n' +
                    '  <input type="text" id="campaign_name" class="form-control" name="campaign_name" value="'+json.campaign_name+'" readonly/>\n' +
                    '</div>'
                html += '' +
                    '<div class="mb-3">\n' +
                    '  <label for="description" class="form-label">Campaign Description</label>\n' +
                    '  <textarea rows="1" type="text" class="form-control" name="description" readonly>'+json.description+'</textarea>\n' +
                    '</div>'

                $(".additionals").html(html)
                setDonorsInfoData(json);
                setShowDonorsInfo(true);
                setHasError(false);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                Swal.close();
                Swal.fire({
                    title: "Failed",
                    icon: "error",
                    html: 'Error Occured',
                    showConfirmButton: false,
                    allowOutsideClick: !0
                })
                $("#campaign_id").val("")
                setDonorsInfoData(null);
                setShowDonorsInfo(false);
                setHasError(true);

            }
        });
    }

    const handleCampaignChange = (event) => {
        const campaign_id = $("#campaign_id").val();
        if (campaign_id){
            validate_campaign_id($("#campaign_id").val());
        }
    };

    const contributeNowForm = (e) =>{

        e.preventDefault();

        $('textarea').removeAttr('disabled')

        $("input[name=transaction]").attr({'disabled':true})

        var form_data = $("#contribute_now_form").serialize();

        let output = '';
        const regex = /([^:]+):([^|]+)/g;

        sample_function().then(function(result) {
            result.replace(regex, (match, col1, col2) => {
                output += `<tr><td>${col1.replace(/\|\|/g, "").trim()}</td><td>${col2.trim()}</td></tr>`;

                var htmlMessage = `<div style="font-size: 14px !important;"> ${output} </div>`;

                Swal.fire({
                    icon: "question",
                    title: "Confirm Transaction",
                    html: `<table style="font-size: 14px !important; text-align: left;" width="100%">${htmlMessage}</table>`,
                    confirmButtonText: "Confirm",
                    confirmButtonColor: "#cc3432",
                    showDenyButton: true,
                    denyButtonText: 'No',
                    denyButtonColor: 'grey',
                    allowOutsideClick: !1,
                }).then((result) => {

                    if (result.isConfirmed) {

                        Swal.fire({
                            title: "Processing",
                            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
                            allowOutsideClick: !1,
                            showConfirmButton: false,
                        });

                        $.ajax({
                            method: "POST",
                            url: api_root()+"/campaign_contribution/",
                            data : form_data,
                            success: function(res){
                                if (res.error){
                                    Swal.fire({
                                        icon: "error",
                                        title: "An Error Occurred",
                                        confirmButtonColor: "#cc3432",
                                        allowOutsideClick: !0
                                    })
                                    return
                                }

                                Swal.fire({
                                    html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/> Just a Moment',
                                    title: "Hold On",
                                    showConfirmButton: false,
                                    allowOutsideClick: !1
                                })

                                if(!res.reference){
                                    // redirect
                                    $(".visa_redirect").html(res.message)
                                    // res.message
                                }

                                if (res.reference){
                                    Swal.fire({
                                        html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/>'+res.message,
                                        title: "Please wait",
                                        showConfirmButton: false,
                                        allowOutsideClick: !1
                                    })
                                    check_status(res.reference[0], null, true, 3000)
                                }
                            }, error: function(){
                                Swal.close();
                                Swal.fire({
                                    icon: "error",
                                    title: "An Error Occurred",
                                    confirmButtonColor: "#cc3432",
                                    allowOutsideClick: !0
                                })
                            }
                        })

                        $('textarea').attr({'disabled':''})

                    }
                })
            })
        })

    }


    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">Crowd Funding</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="contribute_now_form" onSubmit={contributeNowForm}>
                                <div className="mb-3">
                                    <label htmlFor="campaign_id" className="form-label">Campaign ID</label>
                                    <input type="text" className="form-control" name="campaign_id" onBlur={handleCampaignChange} id="campaign_id" placeholder="Enter Campaign ID" required/>
                                </div>
                                <TransactionType transaction="contribution" />

                                <div className="additionals"></div>

                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">Amount (UGX)</label>
                                    <input type="number" className="form-control" name="amount" id="amount" placeholder="Enter amount (ugx)" required/>
                                </div>

                                {showDonorsInfo && !hasError && <DonorsInfo data={donorsInfoData} />} {/* Show DonorsInfo only when showDonorsInfo is true and hasError is false */}

                                <PaymentMethod />

                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contributions;