import Swal from "sweetalert2";
import $ from "jquery";
import toast from 'react-hot-toast';

var charge = 0
var vcharge = 0
var _type = null;
var total_deductible = 0;

const fetchData = async () => {
    var response = await fetch(api_root()+'/information_required/');
    const data = await response.json();
    sessionStorage.setItem('information_required', JSON.stringify(data));
};

// Execute the AJAX request every 1 minute
setInterval(fetchData, 60000);

function addCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function mytransaction_charge(amount, transaction_type) {
  charge = $('#form_id_main').data('charge') || $('#contribute_now_form').data('charge')
  var _type = charge.split("|")
  if (_type[1] === "P") {
    charge = _type[0]
    if (transaction_type === "nas") {
      charge = 2.5
    }
    charge = parseInt(amount) * parseFloat(charge / 100)
  }


  if (transaction_type === "send_money") {
    charge = charge + 100
  }
  return charge
}

export function sample_function() {

  return new Promise(function(resolve, reject) {

    var collection_msisdn = $('#collection_msisdn').val()
    var recipient_msisdn = $('#recipient_msisdn').val()
    var email = $('#email').val()
    var name = $('#name').val()
    var phone_number = $('#phone_number').val()
    var transaction_type = $("input[name=transaction]#id_transaction_0").val()
    var payment_type = $('input[name=payment_type]:checked').val();
    var data_bundle = $('#id_data_package option:selected').val()
    var amount = $('#amount').val()

    var item_name = $('#id_item option:selected').text()
    var quantity = 1;

    var nas_reference = $('#id_nas_reference').val()
    var consinee_email = $('#id_consinee_email').val()
    var consinee_name = $('#id_consinee_name').val()
    var consinee_phone_number = $('#id_consinee_phone_number').val()

    var charge = 0

    var trx = "";
    var trx_amt = "";

    if (transaction_type === "data") {
      trx = "Data"
      trx_amt = "Data Bundle: " + $('#id_data_package option:selected').text() + "||"
      amount = data_bundle.split("|")[1]
    }

    if (transaction_type === "airtime") {
      trx = "Airtime"
      trx_amt += "Amount: " + addCommas(amount) + "||"
    }

    if (transaction_type === "energy") {
      trx = "Buy Goods"
      trx_amt += "Amount: " + addCommas(amount) + "||"
    }

    if (transaction_type === "nas") {
      trx = "NAS Payment"
      trx_amt += "Nas Reference: " + nas_reference + "||"
      trx_amt += "Consignee Name: " + consinee_name + "||"
      trx_amt += "Consignee Email: " + consinee_email + "||"
      trx_amt += "Consignee Phone Number: " + consinee_phone_number + "||"
      trx_amt += "Consignee Phone Number: " + consinee_phone_number + "||"
      charge = mytransaction_charge(amount, transaction_type)

    }

    if(transaction_type === "contribution"){
      trx = "Contributions"
      trx_amt += "Amount: " + addCommas(amount) + "||"
      trx_amt += "Campaign Name: " + $("#campaign_name").val() + "||"
    }

    if(transaction_type === "buy_ticket"){
      trx = "Buy Event Tickets"
      trx_amt += "Tickets: " + $("#tickets_no").text() + "||"
      trx_amt += "Amount: " + addCommas(amount) + "||"
    }

    if(transaction_type === "send_money"){
      trx = "Send Money"
      trx_amt += "Amount: " + addCommas(amount) + "||"
    }

    vcharge = 0
    if (payment_type === "visa") {
      vcharge = $('#form_id_main').data('visa') || $('#contribute_now_form').data('visa') || $('#buy_ticket_now_form').data('visa') 
      vcharge = parseInt(amount) * parseFloat(vcharge / 100)
    }


    if ((transaction_type === "buy_ticket") || (transaction_type === "contribution") || (transaction_type === "send_money") || (transaction_type === "airtime") || (transaction_type === "data")) {
      // trx_amt = "Amount: " + addCommas(amount) + "||"
      charge = $('#form_id_main').data('charge') || $('#contribute_now_form').data('charge') || $('#buy_ticket_now_form').data('charge') 
      _type = charge.split("|")
      if (_type[1] === "P") {
        charge = _type[0]
        charge = parseInt(amount) * parseFloat(charge / 100)
      }

      charge = charge + 100

    }

    total_deductible = parseInt(amount) + parseInt(charge) + parseInt(vcharge)

    trx_amt += "Transaction Type: " + trx + "||";
    if (transaction_type === "energy") {
      trx_amt += "Item: " + item_name + "||";
      trx_amt += "Quantity: " + quantity + "||";
    }

    if (payment_type === "mobile_money") {
      trx_amt += "Collection Number: " + collection_msisdn + "||";
      trx_amt += "Charge: " + parseInt(charge) + "||";
    }
    if (payment_type === "visa") {
      trx_amt += "Visa Details: " + email + "</br>" + name + "</br>" + phone_number + "||";
      trx_amt += "Visa Charge: " + vcharge + "||";
      trx_amt += "Charge: " + parseInt(charge) + "||";
    }
    if (transaction_type !== "nas" && transaction_type !== "contribution" && transaction_type !== "buy_ticket") {
      trx_amt += "Recipient Number: " + recipient_msisdn + "||";

      /*Ajax request to validate the phone number*/
      $.ajax({
          type: 'GET',
          url: api_root() + `/validate_recipient/${recipient_msisdn}/`,
          beforeSend: function () {
            Swal.fire({
              html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/>'+recipient_msisdn+'',
              title: "Validating",
              showConfirmButton: false,
              allowOutsideClick: !1
            })
          },
          success: function (res) {
            if (res.error) {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Failed",
                confirmButtonColor: "#cc3432",
                html: res.message,
                showConfirmButton: true,
                allowOutsideClick: !0
              })
            }

            if(res.verified_names){
              trx_amt += "Recipient Names: " + res.verified_names + "||";
            }

            trx_amt += "Total Deductible Amount: " + addCommas(total_deductible) + "||";

            resolve(trx_amt)

          }

      })

    }else{

      trx_amt += "Total Deductible Amount: " + addCommas(total_deductible) + "||";
      resolve(trx_amt)
    }


  })

}

export async function handleFormSubmit(event) {
  // Prevent default action
  event.preventDefault();

  const regex = /([^:]+):([^|]+)/g;

  let output = '';

  var collection_msisdn = $('#collection_msisdn').val()
  var amount = $('#id_amount').val()
  var prn = $('#id_prn').val()
  var account = $('#id_account').val()
  var bill_item = $('#id_bill_item').val()
  var email = $('#id_email').val()
  var prn_phone_number = $('#id_prn_phone_number').val()
  var transaction_type = $('input[type=radio][name=transaction]:checked').val();
  var payment_type = $('input[type=radio][name=payment_type]:checked').val();
  var csrf = $("input[name=csrfmiddlewaretoken]").val();

  sample_function().then(function(result) {
    result.replace(regex, (match, col1, col2) => {
      output += `<tr><td>${col1.replace(/\|\|/g, "").trim()}</td><td>${col2.trim()}</td></tr>`;

      var htmlMessage = `<div style="font-size: 14px !important;"> ${output} </div>`;

      transaction_type = $("input[name=transaction]").val()

      if (transaction_type === "ura" || transaction_type === "kcca" || transaction_type === "nwsc" || transaction_type === "electricity") {
        // First Verify the transaction

        Swal.fire({
          title: "Validating",
          html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Validating...</span> </div>',
          allowOutsideClick: !1,
          showConfirmButton: false,
        });

        var charge = $('#form_id_main').data('charge')
        _type = charge.split("|")
        if(_type[1] === "P"){
            charge = _type[0]

        }
        csrf = $("input[name=csrfmiddlewaretoken]").val();
        var message = "";
        $.ajax({
            url : api_root()+'/ura/verify/',
            type: 'POST',
            data : $("#form_id_main").serialize(),
            success: function(response) {
                if(!response.error){
                    var r = response.response

                    charge = Math.round(parseInt(r.total_deductible_amount) * parseFloat(charge/100))

                    message = "<tr><td>PRN: </td><td>" + r.account_number +"</td></tr>";
                    message += "<tr><td>Account Name: </td><td>" + r.account_name + "</td></tr>";
                    message += "<tr><td>Phone Number: </td><td>" + r.phone_number + "</td></tr>";
                    message += "<tr><td>Service Name: </td><td>" + r.service_name + "</td></tr>";
                    message += "<tr><td>"+ transaction_type.toUpperCase() +" Payment: </td><td>" + addCommas(r.amount) + "</td></tr>";
                    if(r.transaction_fee){
                      message += "<tr><td>Transaction Fee: </td><td>" + addCommas(r.transaction_fee + charge) + "</td></tr>";
                    }else{
                      message += "<tr><td>Charge: </td><td>" + addCommas(parseInt(charge)) + "</td></tr>";
                    }
                    message += "<tr><td>Total Amount: </td><td>" + addCommas(r.total_deductible_amount + charge) + "</td></tr>";
                    message += "<tr><td>Payment Number: </td><td>" + collection_msisdn + "</td></tr>";
                    message += "";

                    Swal.fire({
                  icon: "question",
                  title: "Confirm Transaction",
                  html: `<table style="font-size: 14px !important; text-align: left;" width="100%">${message}</table>`,
                      confirmButtonText: "Confirm",
                      confirmButtonColor: "#cc3432",
                      showDenyButton: true,
                      denyButtonText: 'No',
                      denyButtonColor: 'grey',
                      allowOutsideClick: !1,
                  }).then((result) => {
                    if (result.isConfirmed) {
                            Swal.fire({
                              title: "Please Wait",
                              html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
                              allowOutsideClick: !1,
                              showConfirmButton: false,
                            });
                            $.ajax({
                                url : api_root()+"/",
                                type: "POST",
                                data : {
                                  amount: amount,
                                  collection_amount: r.total_deductible_amount + charge,
                                  transaction_charge:r.transaction_fee,
                                  total_deductible_amount: r.total_deductible_amount,
                                  collection_msisdn: collection_msisdn,
                                  prn: prn,
                                  account:account,
                                  bill_item:bill_item,
                                  prn_phone_number: prn_phone_number,
                                  email: email,
                                  payment_type: payment_type,
                                  transaction: transaction_type,
                                  validation_id: r.validation_id,
                                  csrfmiddlewaretoken: csrf
                                },
                                success: function(response) {
                                    if(!response.error){
                                        toast.success(response.message, { duration: 5000 });
                                        Swal.close()
                                        Swal.fire({
                                          title: "Processing Transaction",
                                          html: `<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/> ${response.message}`,
                                          allowOutsideClick: !1,
                                          showConfirmButton: false,
                                        });

                                        check_status(response.reference[0], response.reference[1])
                                    }else{
                                      Swal.close();
                                      Swal.fire({
                                          icon: "error",
                                          title: "Failed",
                                          html: "Sorry! there was an issue with your transaction. If Money was deducted and payment not done, please contact us",
                                          confirmButtonColor: "#cc3432",
                                          allowOutsideClick: !0
                                      })
                                    }
                                },
                                error: function() {
                                    Swal.close();
                                    Swal.fire({
                                        icon: "error",
                                        title: "Error",
                                        html: "Sorry! Please try again later.",
                                        confirmButtonColor: "#cc3432",
                                        allowOutsideClick: !0
                                    })
                                }
                            })
                    }else{
                      Swal.close()
                    }
                  });
                }else{
                  Swal.close();
                  Swal.fire({
                      icon: "error",
                      title: "Error",
                      html: "Sorry! transaction failed. Please try again later.",
                      confirmButtonColor: "#cc3432",
                      allowOutsideClick: !0
                  })
                }
            },
            error: function() {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Close",
                    confirmButtonColor: "#cc3432",
                    allowOutsideClick: !0
                })
            }
        });

      } else {
        submit_request(htmlMessage)
      }

    });
  })

}

function enter_token_display(response) {
  Swal.fire({
    title: 'Enter token',
    html: response.message,
    input: 'number',
    confirmButtonText: "Confirm",
    confirmButtonColor: "#cc3432",
    inputAttributes: {
      autocapitalize: 'off',
      class: 'form-control form-control-sm',
      placeholder: 'Enter token',
      maxlength: 6
    },
    customClass: {
      input: 'form-control-sm'
    },
    showCancelButton: false,
    showDenyButton: true,
    denyButtonText: "Resend Token",
    denyButtonColor: 'grey',
    closeOnClickOutside: false,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      const token = result.value;

      $.ajax({
        type: 'POST',
        url: api_root() + `/${token}/`,
        data: $("#form_id_main").serialize(),
        beforeSend: function () {
          Swal.fire({
            title: "Processing",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            allowOutsideClick: !1,
            showConfirmButton: false,
          });
        },
        success: function (res) {

          if (res.error) {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Failed",
              confirmButtonColor: "#cc3432",
              html: res.message,
              showConfirmButton: true,
              allowOutsideClick: !0
            })
            return;
          }

          toast.success(res.message, { duration: 5000 });
          Swal.fire({
            title: "Processing Transaction",
            html: `<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/> ${res.message}`,
            allowOutsideClick: !1,
            showConfirmButton: false,
          });

          var ref1 = res.reference[0]
          var ref2 = res.reference[1]

          check_status(ref1, ref2);

        },
        error: function () {
          Swal.close();
          enter_token_display(response)
        }
      });

      // process_with_token();
    } else if (result.isDenied) {

      $.ajax({
        type: 'GET',
        url: api_root() + `/resend-token/${$("input[name=collection_msisdn]#collection_msisdn").val()}/`,
        beforeSend: function () {
          Swal.fire({
            title: "Processing",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            allowOutsideClick: !1,
            showConfirmButton: false,
          });
        },
        success: function (data) {
          toast.success(data.message, { duration: 5000 });
          enter_token_display(response)
        },
        error: function () {
          Swal.close();
          enter_token_display(response)
        }
      });

    }
  })
}

export function check_status(ref1, ref2 = null, ref2_process = false, timer = 1000, callback = null) {
  var tid = setInterval(function () {
    $.ajax({
      url: api_root() + `/reference/${ref1}/`,
      success: function (result) {

        const serverResponse = result.status

        if (serverResponse === "SUCCESSFUL") {
          clearInterval(tid);

          if (ref2_process) {
            Swal.fire({
              icon: "success",
              title: "Your order has been processed",
              confirmButtonText: "Close",
              html: result.message,
              confirmButtonColor: "#cc3432",
              allowOutsideClick: !1,
            })
          }

          if (!ref2_process) {
            var transaction_type = result.transaction_type
            if (transaction_type === 'energy') {
              Swal.fire({
                icon: "success",
                title: "Your Order is being Processed",
                confirmButtonText: "Close",
                html: result.message,
                confirmButtonColor: "#cc3432",
                allowOutsideClick: !1,
              })
              return
            }

            Swal.fire({
              title: "Sending",
              html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Sending...</span> </div>',
              allowOutsideClick: !1,
              showConfirmButton: false,
            });

            if (transaction_type === "nas") {
              ref2 = ref1
            }

            check_status(ref2, null, true, timer, callback)

          }else{
            if (callback && typeof callback === 'function') {
                // Call the callback function if it's provided
                callback();
            }
          }

        }

        if (serverResponse === "PENDING") {

          if (ref2_process) {
            clearInterval(tid);
            check_status(ref1, null, true, timer, callback)
          }
        }

        if (serverResponse === "FAILED") {
          clearInterval(tid);
          Swal.fire({
            icon: "error",
            title: "Failed",
            confirmButtonText: "Close",
            html: result.message,
            confirmButtonColor: "#cc3432",
            allowOutsideClick: !1,
          })
          return
        }
      },
      async: false
    });
  }, timer);
}

function submit_request(htmlMessage) {
  Swal.fire({
    icon: "question",
    title: "Confirm Transaction",
    html: `<table style="font-size: 14px !important; text-align: left;" width="100%">${htmlMessage}</table>`,
    confirmButtonText: "Confirm",
    confirmButtonColor: "#cc3432",
    showDenyButton: true,
    denyButtonText: 'No',
    denyButtonColor: 'grey',
    allowOutsideClick: !1,
  }).then((result) => {

    if (result.isConfirmed) {
      Swal.fire({
        title: "Processing",
        html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
        allowOutsideClick: !1,
        showConfirmButton: false,
      });

      $.ajax({
        url: api_root() + `/`,
        type: "POST",
        data: $("#form_id_main").serialize(),
        success: function (response) {

          if (response.error) {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Failed",
              html: response.message,
              showConfirmButton: false,
              allowOutsideClick: !0
            })
            return;
          }

          if (response.token) {
            Swal.close();

            enter_token_display(response);
            return
          }

          var msg_ = "Hold On"
          if($('input[name="payment_type"]:checked').val() != "visa"){
            msg_ = response.message
          }

          Swal.close();
          Swal.fire({
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/>'+msg_,
            title: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: !1
          })
          if($('input[name="payment_type"]:checked').val() != "visa"){
            check_status(response.reference[0], response.reference[1])
          }else{
            $(".visa_redirect").html(response.message)
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "An Error Occurred",
            confirmButtonColor: "#cc3432",
            allowOutsideClick: !0
          })
        }
      });

    }

  })
}

export function api_root() {
  return "";// "http://192.168.100.2:8008"; // "http://127.0.0.1:8008"; //put the domain for api here ... Dont put trailing slash
}
