import Home from './components/Home';
import BuyAirtime from './components/BuyAirtime';
import BuyData from './components/BuyData';
import GovPayments from './components/GovPayments';
import Enhas from './components/Enhas';
import TransferMM from './components/TransferMM';
import Nwsc from './components/Nwsc';
import ContactUs from './components/ContactUs';
import Electricity from './components/Electricity';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SelectElectricity from './components/SelectElectricity';
import SelectOnestopProducts from "./components/SelectOnestopProducts";
import Contributions from "./components/Contributions";
import BuyTicket from "./components/BuyTicket";
import SelectEvents from "./components/SelectEvents";
import CheckOut from "./components/CheckOut";


function App() {
  return (
    <Router>
      <Routes>

          <Route path="/" caseSensitive={false} element={<Home />} />
          
          <Route path="/buy_airtime" caseSensitive={false} element={<BuyAirtime />} />
          
          <Route path="/transfer_mm" caseSensitive={false} element={<TransferMM />} />
          
          <Route path="/buy_data" caseSensitive={false} element={<BuyData />} />
          
          <Route path="/gov_payments" caseSensitive={false} element={<GovPayments />} />
          
          <Route path="/enhas" caseSensitive={false} element={<Enhas />} />
          
          <Route path="/pay-water" caseSensitive={false} element={<Nwsc />} />

          <Route path="/contact_us" caseSensitive={false} element={<ContactUs />} />

          <Route path="/pay_electricity" caseSensitive={false} element={<Electricity />} />

          <Route path="/electricity" caseSensitive={false} element={<SelectElectricity />} />

          <Route path="/events" caseSensitive={false} element={<SelectEvents />} />

          <Route path="/crowdfunding" caseSensitive={false} element={<Contributions />} />

          <Route path="/buy_ticket" caseSensitive={false} element={<BuyTicket />} />

          <Route path="/checkout" caseSensitive={false} element={<CheckOut />} />

      </Routes>

    </Router>
  );
}

export default App;
