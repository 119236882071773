import React from "react";
import PaymentMethod from "./PaymentMethod";
import { handleFormSubmit } from './api';
import Header from "./Header";
import Footer from "./Footer";
import TransactionType from "./TransactionType";
import { useLocation } from "react-router-dom";

function GovPayments(){

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if(!searchParams || !searchParams.get('trans')){
        window.location.href = './';
    }

    const transaction = searchParams.get('trans');

    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">Pay {transaction.toUpperCase()}</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="form_id_main" onSubmit={handleFormSubmit}>
                                <TransactionType transaction={transaction} />
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email Address</label>
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Enter email address" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="prn" className="form-label">Payment Reference Number (PRN)</label>
                                    <input type="text" className="form-control" name="prn" id="prn" placeholder="Enter PRN" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="prn_phone_number" className="form-label">Phone Number</label>
                                    <input type="number" className="form-control" name="prn_phone_number" id="prn_phone_number" placeholder="Enter Phone Number" required/>
                                </div>

                                <PaymentMethod />
                                
                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default GovPayments;