import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { api_root } from "./api";
import 'parsleyjs';

function Footer(){
    function CurrentYear() {
        return new Date().getFullYear();
    }

    document.addEventListener('contextmenu', e => {
        e.preventDefault();
    });

    const [data, setData] = useState(null);
    var response = null;
    useEffect(() => {
        const sessionData = sessionStorage.getItem('information_required');
        if (sessionData) {
            setData(JSON.parse(sessionData));
            page_defaults()
        } else {
            const fetchData = async () => {
                if(response){
                    return;
                }
                response = await fetch(api_root()+'/information_required/');
                const data = await response.json();
                sessionStorage.setItem('information_required', JSON.stringify(data));
                setData(data);
                page_defaults()
            };
            fetchData();
        }

        function page_defaults(){
            const mainForm = $("#form_id_main , #buy_ticket_now_form");
            if (sessionStorage.getItem('information_required')) {
                const sample_data = JSON.parse(sessionStorage.getItem('information_required'))
                mainForm.attr("data-visa", sample_data.visa_charge);
                mainForm.attr("data-charge", sample_data.charge.amount + "|" + sample_data.charge.charge_type.charAt(0));
                if(sample_data.contribution_charge){
                    $("#contribute_now_form").attr("data-visa", sample_data.visa_charge);
                    $("#contribute_now_form").attr("data-charge", sample_data.charge.amount + "|" + sample_data.charge.charge_type.charAt(0));
                }else{
                    $("#contribute_now_form").attr("data-visa", 0);
                    $("#contribute_now_form").attr("data-charge", 0 + "|" + sample_data.charge.charge_type.charAt(0));

                }
                var html = "";
                if(sample_data.notifications){
                    sample_data.notifications.forEach(notification => {
                        html += `
                            <div class="alert alert-warning" role="alert">
                            ${notification.notification}
                            </div>
                        `;
                    });
                    $("#onestop_notifications").html(html);
                }

                // Get the onestop_ads div element
                var onestop_ads_div = $("#onestop_ads");

                if(sample_data.advertisement){
                    // Check if the div is empty
                    if (onestop_ads_div.html().trim() === "") {
                        // Loop through each advertisement
                        $.each(sample_data.advertisement, function(index, ad) {
                        // Create a new carousel item div element
                        var carousel_item_div = $("<div>").addClass("carousel-item");

                        // Create a new img element with the advertisement image and description
                        var img_element = $("<img>").attr("src", ad.image_url)
                                                    .attr("alt", ad.description)
                                                    .addClass("d-block img-fluid");

                        // Append the img element to the carousel item div
                        carousel_item_div.append(img_element);

                        // Append the carousel item div to the onestop_ads div
                        onestop_ads_div.append(carousel_item_div);

                        // Make the first item active
                        if (index === 0) {
                            carousel_item_div.addClass("active");
                        }
                        });
                    }
                }

                
            }
        }

        $("form").parsley()
        $("input[name=amount]").attr({min : 500})

    }, []);


    return (
        <div className="col-md-12 mt-4 text-center">

            <ul className="list-inline">
                <li className="list-inline-item">
                    <Link to="#" className="social-list-item bg-primary text-white border-primary">
                        <i className="mdi mdi-facebook fa-2x"></i>
                    </Link>
                </li>
                <li className="list-inline-item">
                    <Link to="https://t.me/joinchat/QJWhnRPtvVc1K6L9dc3axQ" className="social-list-item bg-info text-white border-info">
                        <i className="mdi mdi-telegram fa-2x"></i>
                    </Link>
                </li>
                <li className="list-inline-item">
                    <Link to="https://wa.me/256393193345" className="social-list-item bg-success text-white border-success">
                        <i className="mdi mdi-whatsapp fa-2x"></i>
                    </Link>
                </li>
            </ul>
            <p>Powered by <Link to="https://blinkpay.co.ug/"><img src="https://www.1stop.co.ug/static/img/logo.jpg" style={{width:80}}/></Link></p>
            
            <p><Link to="https://www.blinkpay.co.ug/blink-systems-terms-and-conditions">Blinkpay Terms and Conditions</Link></p>
            <p>Copyright &copy; 2019 - <CurrentYear /> 1Stop.</p>
        </div>
    )
}

export default Footer;