import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { addToCart, getCart, clearCart, updateCartItem, deleteCartItem, getCartItemQuantity, isPackageInCart, getCartTotalAmount, getCartTotalQuantity } from "./cartFunctions";

function EventsInfo(props) {
    const event = props.event;
    const packages = props.packages;

    const [selectedPackage, setSelectedPackage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [inputQuantity, setInputQuantity] = useState(1); // New state for input quantity
    const [subtotal, setSubtotal] = useState(0); // New state for subtotal

    const [totalTickets, setTotalTickets] = useState(getCartTotalQuantity());
    const [totalAmount, setTotalAmount] = useState(getCartTotalAmount());

    const handleCardClick = (pkg) => {
        setSelectedPackage(pkg);
        setInputQuantity(getCartItemQuantity(pkg.id) || 1); // Reset the input quantity to 1 when a new package is clicked
        setShowModal(true);
    };

    const handleClearCart = () => {
        clearCart();
        setTotalTickets(getCartTotalQuantity())
        setTotalAmount(getCartTotalAmount())
    };

    const handleAddToCart = () => {
        if (selectedPackage) {
            const cartItem = {
                packageId: selectedPackage.id,
                quantity: inputQuantity,
                subtotal: selectedPackage.amount * inputQuantity,
                package: selectedPackage,
            };
            addToCart(cartItem);
            setTotalTickets(getCartTotalQuantity())
            setTotalAmount(getCartTotalAmount())

            setShowModal(false);
        }
    };

    const handleRemoveItem = ()=>{
        if (selectedPackage.id) {
            deleteCartItem(selectedPackage.id)
            setShowModal(false);
        }
    }


    const decreaseQuantity = () => {
        if (inputQuantity > 1) {
            setInputQuantity(inputQuantity - 1);
        }   
    };

    const increaseQuantity = () => {
        if (inputQuantity < selectedPackage.available_tickets) {
            setInputQuantity(inputQuantity + 1);
        }
    };

    useEffect(() => {
        if (selectedPackage) {
            setSubtotal(selectedPackage.amount * inputQuantity);
        }
    }, [selectedPackage, inputQuantity]);

    return (
        <>
        <div>
            {event.banner && (
                <div className="row text-center">
                    <img src={event.banner} alt="" style={{ maxHeight: '300px' }} />
                </div>
            )}

            <div className="m-3">
                <h4 className="text-center card-title">{event.event_name}</h4>
                <div className="d-flex justify-content-between">
                    <span className="text-default">Venue : {event.venue}</span>
                    <span className="text-white">&nbsp;</span>
                    <span className="text-default">Start Date : {event.start_date} {event.start_time}</span>
                </div>
            </div>

            <div className="row">
                {packages.map((pkg) => (
                    <div key={pkg.id} className="col-6" onClick={() => handleCardClick(pkg)}>
                        {pkg.available_tickets > 0 ? (
                            <div className="card bg-custom mb-3">
                                <div className="card-body text-white text-center">
                                    <h5 className="card-title text-white">{pkg.package_name}</h5>
                                    <p className="card-text">Amount: {pkg.amount} /=</p>
                                </div>
                            </div>
                        ) : (
                            <div className="card bg-dark mb-3">
                                <div className="card-body text-white text-center">
                                    <h5 className="card-title text-white">{pkg.package_name}</h5>
                                    <p className="card-text">Amount: {pkg.amount} /=</p>
                                    <p className="card-text">Out of Stock</p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
            <h5>Total Tickets: {totalTickets}</h5>
            <h5>Total Amount: {totalAmount} /=</h5>
        </div>

        <div className="mt-3 d-flex justify-content-between">
            <Link className="btn btn-dark waves-effect waves-light" onClick={() => handleClearCart()} to="javascript:void(0)" >Clear Cart</Link>
            {totalTickets > 0 && 
            <Link className="btn btn-danger btn-custom waves-effect waves-light" to="/checkout">Check Out</Link>
            }
        </div>

        {selectedPackage &&
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Buy Ticket : {selectedPackage.package_name} @ {selectedPackage.amount} /=</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedPackage && (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                            <button
                                className="btn btn-custom text-white mx-1"
                                onClick={decreaseQuantity}
                            >
                                -
                            </button>
                        <div className="input-group">
                            <span className="input-group-text">Tickets:</span>
                            <input
                                type="number"
                                readOnly
                                className="form-control"
                                min="1"
                                value={inputQuantity}
                                max={selectedPackage.available_tickets}
                                onChange={(e) => {
                                    const newValue = parseInt(e.target.value);
                                    if (newValue >= 1 && newValue <= selectedPackage.available_tickets) {
                                        setInputQuantity(newValue);
                                    }
                                }}
                            />
                        </div>
                        <button
                            className="btn btn-custom text-white mx-1"
                            onClick={increaseQuantity}
                        >
                            +
                        </button>
                        

                    </div>
                    <div className="text-center">
                        <small className="text-danger">Available Tickets : {selectedPackage.available_tickets}</small>
                    </div>

                    <div className="mt-3">
                        <h2 className="text-center">Sub Total: {subtotal} /=</h2>
                    </div>

        </>
                )}
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button variant="primary" onClick={handleAddToCart}>
                    Add To Cart
                </Button>
                {isPackageInCart(selectedPackage.id) && (
                <Button variant="dark" onClick={handleRemoveItem}>
                    Remove from cart
                </Button>
                )}
                {totalTickets > 0 && 
                <Link variant="danger" className="btn btn-danger btn-custom waves-effect waves-light" to="/checkout">
                    Check Out
                </Link>
                }
            </Modal.Footer>
        </Modal>
        }
        </>
    );
}

export default EventsInfo;
